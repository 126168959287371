import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Breadcrumbs`}</h2>
    <ul>
      <li parentName="ul"><span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "0",
            "marginRight": "0",
            "maxWidth": "67px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "104.4776119402985%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAABYlAAAWJQFJUiTwAAACc0lEQVQ4y41V2XaiQBDNB8w5zmgii4iC24hZVOK+4BKDsiXRZP7/T+6cKoGgiZqHC0139a2qvl3FlZjTcAqCXDwzV/x2z9WpjUmCG1FlCPJl51fnyCKUqwZKVQM/ySYmvBbyyGTz/E5ClIuwZgsGpXm8nskqHH1EyilnpQLuH0wsFitYsyWDxt3eCP3hBIPhBG2zh9HYQqc7xDxhN188oVa/Zw6OkNhLFQMvr2+8qdXuMtpml50MR1OsVjam1gLjiYVmq4OW2WXQuNcfwQ9eIef1fYQUtnHbxOrZRiotxmkQorS00l8U9Cr+XMvxfEb4tHFdn9cpSiasNx7wbK9xI+YhKXp80Dm1BFnR+YzUYhXGbQt6uR6nR6B11/OhapVPQiMkvBaU2JDmN47HKf/OSNg4Lm/0/YDTjISQctpXwnqCUAojonPdbndwXA+iXGDyUqWOXF5H8PLK62QnKz8gTGdzrJ7jeHjb7nD3YGL59IxytY5fqSzstQPjrsnX7CIhKVXQa9judphac9j2huF6AczHHvqDMTw/iFWVLhHSZKXWQPuxx6oKUgGd7gDDsYX1xsVsvkRRr8XCXDjDvcrkmRZpTKAxQ1TZZh+ZHqqs8bU5UrnJaZGaB2UlKBz1cbkdl53nBSho4T2kB334wQunM5nOMJnOw/cpzBlUOU8rG+u1w3V+UMt0JaicqMR2u3e02h2YnT7Mx0PQ2dKZfnz8Y4GoTNUwuoNuQxOpjIRytcFeo/TpahyChFM5KlI6fZOLW1ccYbInyvkSezzX++iqkE2yTON+uM9dO/AShX/cfI9tkt/7bh5G+N0/5GsnL551Eq3/B45MXNHI/hT6AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/30c5a123c5bfd4cd675713661d506403/381f8/ec-adCenter.webp 67w"],
                "sizes": "(max-width: 67px) 100vw, 67px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png 67w"],
                "sizes": "(max-width: 67px) 100vw, 67px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/30c5a123c5bfd4cd675713661d506403/55ef4/ec-adCenter.png",
                "alt": "icon-adCenter",
                "title": "icon-adCenter",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{` > Campaigns > Add `}</li>
    </ul>
    <h2>{`Tab: `}<em parentName="h2">{`Summary`}</em></h2>
    <p>{`Statistics of the campaign.`}</p>
    <h2>{`Tab: `}<em parentName="h2">{`Contacts`}</em></h2>
    <p>{`List of contacts available for the campaign. `}</p>
    <br />_Add contact_ is available:
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`NAME`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the contact`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`PHONE`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone of the contact`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`EMAIL`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email of the contact`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`ORGANIZATION`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Company of the contact`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`EXTERNAL ID`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`External Id identifier for the contact`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`STATUS`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` - Active`}<em parentName="td">{` or _Inactive`}</em></td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`At least `}<em parentName="p">{`Email`}</em>{`, `}<em parentName="p">{`Phone`}</em>{` or `}<em parentName="p">{`External id`}</em>{` must be filled, depending on the offsite campaign type.`}</p>
    </blockquote>
    <h2>{`Tab: `}<em parentName="h2">{`Links`}</em></h2>
    <p>{`Statistics of clicks and opened links for each campaign.`}</p>
    <h2>{`Tab: `}<em parentName="h2">{`Settings`}</em></h2>
    <p>{`The `}<em parentName="p">{`Settings`}</em>{` Tab is where additional settings or updated settings can be defined for each campaign.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`NAME`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the campaign.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`CONTACT LIST`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The list of contacts available.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`LANGUAGE`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required Outbound voice and Callcenter Outbound`}</strong>{`- Select the language form the list available for the webcare.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`DISABLE CAMPAIGN WHEN CONTACT LIST ENDS`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` - `}<em parentName="td">{`Yes`}</em>{` or `}<em parentName="td">{`No`}</em>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`SKILL`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The skill that will manage the campaign.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Anonymous call`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required Outbound voice and Callcenter Outbound`}</strong>{` - `}<em parentName="td">{`Yes`}</em>{` if we want the number that appears to the contact to be anonymous or `}<em parentName="td">{`No`}</em>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Contact attempts`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required Outbound voice and Callcenter Outbound`}</strong>{` - `}<em parentName="td">{`Yes`}</em>{` or `}<em parentName="td">{`No`}</em>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Minimum time between contact attempts`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required Outbound voice and Callcenter Outbound`}</strong>{` - Define a perido in seconds.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Validate business hours`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required Outbound voice and Callcenter Outbound`}</strong>{` - `}<em parentName="td">{`Yes`}</em>{` or `}<em parentName="td">{`No`}</em>{`. It should be `}<em parentName="td">{`Yes`}</em>{` if operators should be available to reply to the campaign.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Expiration of contact after creation`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required Outbound voice and Callcenter Outbound`}</strong>{` - `}<em parentName="td">{`Yes`}</em>{` or `}<em parentName="td">{`No`}</em>{`. `}<em parentName="td">{`Yes`}</em>{` if you don't want to repeat the contact after the call.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Set maximum branch occupancy rate`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required Outbound voice and Callcenter Outbound`}</strong>{` - `}<em parentName="td">{`Yes`}</em>{` or `}<em parentName="td">{`No`}</em>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Simultaneous threads`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required Outbound voic and Callcenter Outbound`}</strong>{` - Define the number where 5 is the maximum available.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`From - Name`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required outbound email`}</strong>{` - From name`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`From - Email`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required outbound email`}</strong>{` - From email`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Cc`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Outbound email`}</strong>{` - Additional Cc`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`BCC`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Outbound email`}</strong>{` - Additional Bcc`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`REPLY TO`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Outbound email`}</strong>{` - Additional Bcc`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`TRACKING LINKS`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Outbound email`}</strong>{` - Define track links.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`TRACKING IMAGE`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Outbound email`}</strong>{` - Define track images.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`EMBED IMAGES`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Outbound email`}</strong>{` - Embebed images`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`EXTERNAL CAMPAIGN`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Outbound email`}</strong>{` - Link an external campaign`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`FROM`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required Outbound SMS and SMS with Callback`}</strong>{` - Define the from.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`EXPIRATION TIME`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required Outbound SMS and SMS with Callback`}</strong>{` - A list of possibilities to expire the sms.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`MESSAGE TYPE`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required SMS with Callback`}</strong>{` - `}<em parentName="td">{`SMS`}</em>{` or `}<em parentName="td">{`WAP push`}</em>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`DESTINATION`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required SMS with Callback`}</strong>{` - `}<em parentName="td">{`SKILL`}</em>{`, `}<em parentName="td">{`URL`}</em>{` OR `}<em parentName="td">{`IMAGE`}</em>{`. If `}<em parentName="td">{`SKILL`}</em>{`, need to select if all skill or a specific. If `}<em parentName="td">{`URL`}</em>{` or `}<em parentName="td">{`IMAGE`}</em>{`, need to select the URL or parameter and number.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`LIMIT DELIVERIES PER MINUTE`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required Outbound email and SMS`}</strong>{` - Define a limit to deliver per minute and avoid overloading.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`USE FREQUENCY CAPING`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required Outbound email and SMS`}</strong>{` - `}<em parentName="td">{`No`}</em>{` or `}<em parentName="td">{`Yes`}</em>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`SCHEDULE START`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` - `}<em parentName="td">{`Yes`}</em>{` or `}<em parentName="td">{`No`}</em>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`SCHEDULE END`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` - `}<em parentName="td">{`Yes`}</em>{` or `}<em parentName="td">{`No`}</em>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Tab: `}<em parentName="h2">{`Content`}</em></h2>
    <p>{`The `}<em parentName="p">{`Content`}</em>{` Tab is where the content can be defined for each campaign. `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`SUBJECT`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required Outbound EMAIL`}</strong>{` -  The subjet to be shown on the email.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`TEMPLATE`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required`}</strong>{` - A list defined.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`HEADER`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required Outbound EMAIL`}</strong>{` - Toggle `}<em parentName="td">{`on`}</em>{` or `}<em parentName="td">{`off`}</em></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`HEADER`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required Outbound EMAIL`}</strong>{` - The header to be shown on the email.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`FOOTER`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required Outbound EMAIL`}</strong>{` - Toggle `}<em parentName="td">{`on`}</em>{` or `}<em parentName="td">{`off`}</em></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`FOOTER`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required Outbound EMAIL`}</strong>{` - The footer to be shown on the email.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`TYPE`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required Outbound EMAIL`}</strong>{` - Origin of email content.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`EMAIL TEMPLATE`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required Outbound EMAIL`}</strong>{` - The message to be shown on the email.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`MESSAGE`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required Outbound SMS and SMS with Callback`}</strong>{` - Define the message to be sent.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`UNICODE`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Outbound SMS and SMS with Callback`}</strong>{` - If necessary should be ticked.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`AVAILABLE CHARACTERS`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required Outbound SMS and SMS with Callback`}</strong>{` - The limit number of available characters for SMS`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`ACTIONS`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Outbound voice`}</strong>{` - A sequence of actions to make available on the call: `}<em parentName="td">{`PLAY`}</em>{`, `}<em parentName="td">{`PERSONALIZED AUDIO`}</em>{`, `}<em parentName="td">{`RECORD`}</em>{`, `}<em parentName="td">{`SILENT`}</em>{`, `}<em parentName="td">{`SURVEY`}</em>{`, `}<em parentName="td">{`SCHEDULE`}</em>{`, `}<em parentName="td">{`TRANSFER TO A SKILL`}</em>{` or `}<em parentName="td">{`PRESS 1 TO RESCHEDULE`}</em>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`SKILL`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Required Callcenter Outbound`}</strong>{` - Select a skill to perform the campaign.`}</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`It’s possible to use parameters in the content to personalize it for each entry on the contacts list. `}</p>
    </blockquote>
    <h2>{`Tab: `}<em parentName="h2">{`Tests`}</em></h2>
    <p>{`The `}<em parentName="p">{`Tests`}</em>{` Tab is where we can perform and validate the campaign without activating it. You can define a `}<em parentName="p">{`Send test to destination`}</em>{` and by clicking `}<em parentName="p">{`send`}</em>{` the test is performed.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      